<template>
  <div class="myCreateCircleAllPe">
    <pageHead class="messageMainView_head" title="全部成员" />
    <van-row>
      <van-col span="12">
        <searchBox :placeholder='placeholder' @click.native="toSearch" disabled class="mainView_search" />
      </van-col>
      <van-col span="6"><span @click.prevent="batchAdd">批量添加</span></van-col>
      <van-col span="6"><span @click.prevent="batchDel">批量删除</span></van-col>
    </van-row>

    <!-- 列表 -->
    <div class="vant_List">
      <van-swipe-cell v-for="item in vanSwipeList" :key="item.id">
        <van-card
          :title="item.title"
          class="goods-card"
          :thumb="item.header"
        />
        <template #right>
          <van-button square @click="vanSwipeDel(item.id)" type="danger" text="删除" />
        </template>
      </van-swipe-cell>
    </div>

  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import searchBox from '@/components/searchBox'

export default {
  name: 'myCreateCircleAllPe', // 全部成员
  components: {
    pageHead,
    searchBox,
  },
  data() {
    return {
      placeholder: '',
      vanSwipeList: [
        {
          id: 1,
          title: '极氪员工-李三',
          header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
        },
        {
          id: 2,
          title: '极氪员工-李四',
          header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
        },
        {
          id: 3,
          title: '极氪员工-王五',
          header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
        },
      ]
    }
  },
  methods: {
    // 跳转搜索页面
    toSearch() {
      this.$router.push({
        name: "myCreateCircleSearch",
      });
    },
    // 跳转添加
    batchAdd() {
      this.$router.push({
        name: "batchAdd",

      });
    },
    // 跳转删除
    batchDel() {
      this.$router.push({
        name: "batchDel",
      });
    },
    // 删除
    vanSwipeDel(id) {
      console.log(id)
      this.$dialog.confirm({
        title: '对话框标题',
        message: '你确定要删除该成员吗？',
        confirmButtonText: '删除成员',
        cancelButtonText: '再考虑下'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.myCreateCircleAllPe {
  background: #fff;
  height: 610px;

  ::v-deep .van-search__content {
    border-radius: 20px !important;
  }

  .vant_List {
    margin-top: 10px;
  }

  ::v-deep .van-card {
    padding-bottom: 16px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 24px;
    margin-right: 15px;
    border-bottom: 1px solid #f4f4f4;
  }

  ::v-deep .van-col {
    font-size: 13px;
    color: #fe5800;
    text-align: center;
    line-height: 34px;
  }

  ::v-deep .van-row {
    padding-top: 12px;
  }
}
</style>
